import qs from 'query-string';

import store from '../store';
import history from '../history';
import { getMap } from './map/mapRef';
import debounce from './debounce';

function syncUrlWithState(view) {
  // get UI state for sidebar and map from store
  const uiState = store.getState().ui;
  if (!view) {
    const map = getMap();
    view = { zoom: map.getZoom(), ...map.getCenter().toJSON() };
  }
  const { lat, lng } = view;

  // get current pathname
  const { pathname } = history.location;

  console.log(pathname);

  // object to store all enabled parameters
  const urlQueryState = { utilities: [] };

  const { utilities, ...otherUiState } = uiState;

  Object.entries(utilities).forEach(([key, value]) => {
    if (value) urlQueryState.utilities.push(key);
  });

  // only add parameters that are enabled
  Object.keys(otherUiState).forEach((key) => {
    if (uiState[key]) {
      urlQueryState[key] = uiState[key];
    }
  });

  // convert query object to query string
  const queryString = `?${qs.stringify({
    ...urlQueryState,
    // zoom,
    lat: lat.toFixed(6),
    lng: lng.toFixed(6),
  }, { arrayFormat: 'comma' })}`;

  // update url
  history.replace(`${pathname}${queryString}`);
}

export default debounce(syncUrlWithState, 500);
