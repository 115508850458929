import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import GoogleMap from './GoogleMap';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import UtilityLegend from './UtilityLegend/UtilityLegend';
import Footer from './Footer';
import InfoDrawer from './InfoDrawer/InfoDrawer';
import Show from './Show';

import theme from '../theme';

import { getUiState, toggleSidebar } from '../slices/ui';
import { fetchDisclaimerContent } from '../thunks/fetchDisclaimer';

import { isMobile } from '../utils/browserAndDeviceTests';

import onAppLoad from '../utils/appSetup/onAppLoad';
import { toggleUtilityLegendButton } from '../utils/map/setup/customMapControls';
import { selectInfoDrawerOpen } from '../slices/infoDrawer';
import { selectViewLevel } from '../slices/view';

// add window.focus and window.blur
require('../utils/accessibility/focusHandlers');

const sidebarWidth = '275px';
const utilityLegendWidth = '350px';

const getStyles = ({ sidebar, utilityLegendVisible, drawerOpen, innerHeight }) => ({
  app: {
    display: 'grid',
    height: innerHeight,
    gridTemplateRows: {
      xs: drawerOpen ? `60px minmax(10vh, ${innerHeight}px) auto 60px` : `60px ${innerHeight - 120}px 60px`,
      sm: drawerOpen ? `80px minmax(10vh, ${innerHeight}px) auto 40px` : `80px ${innerHeight - 120}px 40px`,
      md: drawerOpen ? `80px minmax(10vh, ${innerHeight}px) auto 20px` : `80px ${innerHeight - 100}px 20px`,
    },
  },
  mainContent: {
    display: 'flex',
  },
  map: {
    width: `calc(100vw - ${sidebar ? sidebarWidth : '0px'} - ${utilityLegendVisible ? utilityLegendWidth : '0px'})`,
    overflowY: 'scroll',
    height: '100%',
  },
  legend: {
    width: utilityLegendWidth,
    overflowY: 'scroll',
    height: '100%',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    background: '#eee',
  },
});

const MainPage = () => {
  const [utilityLegendAvailable, toggleUtilityLegend] = useState(false);
  const dispatch = useDispatch();

  const drawerOpen = useSelector(selectInfoDrawerOpen);
  const { sidebar, utilities, utilitiesOpen, utilitiesLegendSidebar } = useSelector(getUiState);
  const level = useSelector(selectViewLevel);

  const theme = useTheme();
  const utilityLegendVisible = useMediaQuery(theme.breakpoints.up('md')) && utilityLegendAvailable && utilitiesLegendSidebar;
  const styles = getStyles({ sidebar, utilityLegendVisible, drawerOpen, innerHeight: window.innerHeight });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchDisclaimerContent());
  }, []);

  useEffect(() => {
    toggleUtilityLegendButton(utilityLegendAvailable);
  }, [utilityLegendAvailable]);

  useEffect(() => {
    const shouldShow = level === 'features' && utilitiesOpen && Object.values(utilities).filter((toggled) => toggled).length;
    toggleUtilityLegend(shouldShow);
  }, [level, utilities, utilitiesOpen]);

  useEffect(() => {
    (async function setupApp() {
      await onAppLoad();
      if (isMobile()) dispatch(toggleSidebar(false));
      setLoading(false);
    }());
  }, []);

  if (loading) return '';

  return (
    <>
      <Box sx={styles.app}>
        <Header />
        <Box sx={styles.mainContent}>
          <Sidebar width={sidebarWidth} />
          <Box sx={styles.map}>
            <GoogleMap />
          </Box>
          <Show test={utilityLegendVisible}>
            <Box sx={styles.legend}>
              <UtilityLegend />
            </Box>
          </Show>
        </Box>
        <Show test={drawerOpen}>
          <InfoDrawer />
        </Show>
        <Box sx={styles.footer} id="footer">
          <Footer />
        </Box>
      </Box>
    </>
  );
};

const MainPageWrapper = () => (
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainPage />
    </ThemeProvider>
  </>
);

export default MainPageWrapper;
