import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import SkipNav from './SkipNav';
import Snackbar from './Snackbar';
import Show from './Show';
import MainPage from './MainPage';
import ModalRouter from './Modal/ModalRouter';
import DataIssuesPage from './DataIssuesPage';
import RedirectOnLoad from './RedirectOnLoad';

import { getUiState, toggleSidebar } from '../slices/ui';
import { fetchDisclaimerContent } from '../thunks/fetchDisclaimer';

import { isMobile } from '../utils/browserAndDeviceTests';

import onAppLoad from '../utils/appSetup/onAppLoad';
import { toggleUtilityLegendButton } from '../utils/map/setup/customMapControls';
import { selectModalOpen } from '../slices/modal';
import { selectViewLevel } from '../slices/view';

// add window.focus and window.blur
require('../utils/accessibility/focusHandlers');

const App = () => {
  const [utilityLegendAvailable, toggleUtilityLegend] = useState(false);
  const dispatch = useDispatch();
  const url = window.location.href;
  console.log(url);

  const modalOpen = useSelector(selectModalOpen);
  const { utilities, utilitiesOpen } = useSelector(getUiState);
  const level = useSelector(selectViewLevel);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchDisclaimerContent());
  }, []);

  useEffect(() => {
    toggleUtilityLegendButton(utilityLegendAvailable);
  }, [utilityLegendAvailable]);

  useEffect(() => {
    const shouldShow = level === 'features' && utilitiesOpen && Object.values(utilities).filter((toggled) => toggled).length;
    toggleUtilityLegend(shouldShow);
  }, [level, utilities, utilitiesOpen]);

  useEffect(() => {
    (async function setupApp() {
      await onAppLoad();
      if (isMobile()) dispatch(toggleSidebar(false));
      setLoading(false);
    }());
  }, []);

  if (loading) return '';

  return (
    <>
      <SkipNav />
      <Snackbar />
      <Show test={modalOpen}>
        <ModalRouter />
      </Show>
      <Routes>
        <Route path="/" element={<RedirectOnLoad />} />
        <Route path="/map" element={<MainPage />} />
        <Route
          path="/dataIssues"
          element={<DataIssuesPage />}
        />
      </Routes>
    </>
  );
};

export default App;
