import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default () => {
  const [dataIssues, setDataIssues] = useState({});
  const [syncStatus, setSyncStatus] = useState('');

  useEffect(() => {
    async function fetchData() {
      const data = await fetch('/api/dataIssues');
      const json = await data.json();
      setDataIssues(json);
    }
    fetchData();
  }, []);

  const handleSyncButtonClick = async () => {
    setSyncStatus('Syncing...');
    const resp = await fetch('/api/dataIssues/sync');
    if (resp.status === 200) {
      setSyncStatus('Sync Complete');
    } else {
      setSyncStatus('Sync Failed');
    }
  };

  const getSyncStatusColor = (status) => {
    switch (status) {
      case 'Syncing...': return '#007bbb';
      case 'Sync Complete': return '#00FF00';
      default: return '#FF0000';
    }
  };

  const getDataIssuesLabel = (key) => {
    switch (key) {
      case 'breakingIssues': return { label: 'Breaking Issues', color: '#ff7c7c' };
      case 'nonBreakingIssues': return { label: 'Non-Breaking Issues', color: '#ffef7c' };
      default: return { label: 'Unknown', color: '#CBCBCB' };
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1>Data Issues</h1>
        <button
          onClick={handleSyncButtonClick}
          type="button"
          style={{
            marginLeft: '20px',
            padding: '5px 10px',
            backgroundColor: '#007bbb',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            maxHeight: '40px',
            fontSize: '16px',
            cursor: 'pointer',
          }}
        >
          Sync
        </button>
        {syncStatus === 'Syncing...' && (
          <Box sx={{ marginLeft: '20px', display: 'flex' }}>
            <CircularProgress size="30px" />
          </Box>
        )}
        <p style={{ color: getSyncStatusColor(syncStatus), marginLeft: '10px' }}>{syncStatus}</p>
      </div>
      {Object.entries(dataIssues).length ? Object.entries(dataIssues).map(([key, severity]) => (
        <div
          key={key}
          style={{
            backgroundColor: getDataIssuesLabel(key).color,
            borderRadius: '5px',
            border: '1px solid #000',
            padding: '5px 10px',
            marginBottom: '10px',
          }}
        >
          <h2>{getDataIssuesLabel(key).label}</h2>
          <ul>
            {Object.entries(severity).length ? Object.entries(severity).map(([key, issueLabel]) => (
              <div key={key}>
                <h2>{key}</h2>
                {Object.entries(issueLabel).map(([errorMessageType, errorMessages]) => (
                  <ul key={errorMessageType}>
                    {Array.isArray(errorMessages) ? errorMessages.map((message) => (
                      <li key={message}>{message}</li>
                    )) : <li key={errorMessages}>{errorMessages.length ? errorMessages : 'No Issues Found'}</li>}
                  </ul>
                ))}
              </div>
            )) : 'No Issues Found'}
          </ul>
        </div>
      )) : 'No Issues Found'}
    </>
  );
};
